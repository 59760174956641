// Ну а куда девать то их?
let av = window.av || {};
av.sfTouched = false;

// "а вы точно не робот?"
// самая простая проверка на то, что форму трогали мышкой, пальцем или клавиатурой:
let el = document.querySelector('#subscription_email');
"click touchmove keyup".split(" ").forEach(function (e) {
    el.addEventListener(e, function () { av.sfTouched = true; }, false);
});

document.querySelector("a[href*='live.mts.ru']")&&(document.querySelector("a[href*='live.mts.ru']").target="_blank");